import React from 'react';
import styled from 'styled-components';
import { LogoText } from '../svgs';

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    justify-content: flex-start;
  }
`;

const Placeholder = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;

  p {
    line-height: 1.4;
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    width: 90vw;
  }

  @media (max-width: 650px) {
    margin: 25px 0;
  }
`;

const Tagline = styled.div`
  font-family: var(--font-family-sans);
  font-size: var(--font-size-xl);
  color: var(--color-primary-dark);
  margin: 5px 0 200px;

  @media (max-width: 960px) {
    margin: 10px 0 50px;
  }

  @media (max-width: 650px) {
    font-size: var(--font-size-l);
    margin: 15px 0 50px;
  }
`;

export const App: React.FC = () => {
  return (
    <Root>
      <Placeholder>
        <LogoText iconColor="lightgreen" width="100%" />
        <Tagline>Plastic-free, stress-free party supplies</Tagline>
        <p>
          Sausage&amp;Bubble is a new company that's been set up in response to the current global
          issue with plastics damaging the environment. When it comes to parties (and children's
          parties especially), plastic is everywhere and so we want to make it easy for people to
          avoid plastic and simultaneously make parties easier and less stressful.
        </p>
        <p>
          We will be offering multiple packages, including everything from plates and bowls to jugs,
          serving bowls and tablecloths. We will deliver them to you, pick them up again afterwards,
          and clean everything for you.
        </p>
        <p>
          Please watch this space for more information, as we will be launching the full site as
          soon as we're able. In the meantime, if you want more information about anything, please{' '}
          <a href="mailto:enquiries@sausageandbubble.co.uk">send us an email</a>.
        </p>
      </Placeholder>
    </Root>
  );
};
